import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contact-us',
      name: 'contact',
      component: () => import('@/views/Contact.vue'),
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/second-page',
    //   name: 'second-page',
    //   component: () => import('@/views/SecondPage.vue'),
    //   meta: {
    //     pageTitle: 'Second Page',
    //     breadcrumb: [
    //       {
    //         text: 'Second Page',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      const target = { selector: to.hash, behavior: 'smooth' }
      return new Promise(resolve => {
        if (document.readyState === 'complete') {
          resolve(target)
        } else {
          window.addEventListener('load', () => {
            setTimeout(() => {
              resolve(target)
            }, 150)
          })
        }
      })
    }
    return { x: 0, y: 0 }
  },
})

export default router
